import { Component, OnInit } from '@angular/core';
import { AppellatedivisionService } from '../../../appellatedivision.service';
import { LawyerinfoService } from '../../../lawyerinfo.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-total-case-list-appelate',
  templateUrl: './total-case-list-appelate.component.html',
  styleUrls: ['./total-case-list-appelate.component.css']
})
export class TotalCaseListAppelateComponent implements OnInit {
  adminBdlawserviceFile= environment.adminBdlawserviceFile
  totalcaselistaddata:any = [];
  totalcaselistaddatacount:any = [];
  totalcaselistaddetailsdata:any = [];
  totalcaselisttwdata:any = [];
  totalcasesummarylwdata:any = [];

  remarksData:any = [];

  remarks:any;
 
  search_code:any;
 

  errorMessage: string;
  isLoading: boolean = true;

  status:boolean = false;
  details:boolean = false;
  Totaldetails:boolean = false;

  sadmin:boolean = false;
  ssadmin:boolean = false;
  detailsdata:boolean = false;
  isLoadingImage:boolean = false;
  isLoadingImage2:boolean = false;
  isLoadingImage3:boolean = false;

  current_username: string;
  current_laywerCode: string;
  is_admin: boolean = false;

  lawyerCode: any;
  lawyergroupdata:any = [];

  current_lawyerType: string;

  editRow: boolean = false;
  autoId:any;

  lawyer_ids:any;
  case_type_ids:any;
  case_nos:any;
  case_years:any;


  constructor(
      public rest:AppellatedivisionService, 
      private route: ActivatedRoute, 
      public lawyerinfo_api:LawyerinfoService, 
      private router: Router,
      public app:AppComponent
  ) { 

    if (!this.app.loggedIn()) {
      this.router.navigate(['../']);
    }  

  }


  ngOnInit() {

    this.current_username = this.app.getUserName();
    this.current_laywerCode = this.app.getLawyerCode();
    this.is_admin = this.app.isAdmin(); 

    this.current_lawyerType = this.app.getLawyerType();

    this.getLawyerGroup();


    if(!this.is_admin){
      this.search_code = this.current_laywerCode;
      this.getTotalcaselistad();
      this.getTotalcaselistadcount();
    } 

    if(this.is_admin){
     // this.getTotalcaselisthighcourt();
      this.getTotalcasesummaryLWSadmin();
    } 

  }

  
  getTotalcaselistad() {
    let postData = {
      search_code: this.search_code,
    }

   // console.log(postData);
   this.totalcaselistaddata = [];
   this.isLoadingImage = true

    this.rest
        .getTotalcaselistad(postData)
        .subscribe(
          totalcaselistaddata => {
                this.totalcaselistaddata = totalcaselistaddata
                this.isLoading = false
                this.status=true;
                this.Totaldetails=false;
                this.details=false;
                this.isLoadingImage = false
            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
                this.isLoadingImage = false
            }
        );
  }


  getTotalcaselistadcount() {
    let postData = {
      search_code: this.search_code,
    }
 
   // console.log(postData);
   this.totalcaselistaddatacount = [];

    this.rest
        .getTotalcaselistadcount(postData)
        .subscribe(
          totalcaselistaddatacount => {
                this.totalcaselistaddatacount = totalcaselistaddatacount
                this.isLoading = false
                this.status=true;
            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
            }
        );
  }
  
  
  getTotalcaselistaddetails(l_id,case_type_id) {
    //console.log(l_id);
    //console.log(case_type_id);

    //window.scroll(0,520);

    this.autoId =null;

    window.scrollTo({
      top: 510,
      left: 0, 
      behavior: 'smooth'
    });

    this.isLoadingImage2 = true
    this.detailsdata = false

    let postData = {
      l_id: l_id,
      case_type_id: case_type_id,
    }

    //console.log(postData);

   // this.isLoadingImage = true

   this.totalcaselistaddetailsdata = [];

    this.rest
        .getTotalcaselistaddetails(postData)
        .subscribe(
          totalcaselistaddetailsdata => {
                this.totalcaselistaddetailsdata = totalcaselistaddetailsdata
                this.isLoading = false
                this.details=true;
                this.Totaldetails=false;
                this.isLoadingImage2 = false;
                this.detailsdata = true
            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
                this.isLoadingImage2 = false
            }
        );
  }


  getTotalcaselistCountTW(l_id,case_type_id) {
    //console.log(l_id);
    //console.log(case_type_id);
    let postData = {
      l_id: l_id,
      case_type_id: case_type_id,
    }

    //console.log(postData);
    this.totalcaselisttwdata = [];

    this.rest
        .getTotalcaselistCountTW(postData)
        .subscribe(
          totalcaselisttwdata => {
                this.totalcaselisttwdata = totalcaselisttwdata
                this.isLoading = false
                this.details=true;
            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
            }
        );
  }

  getTotalcasesummaryLW(l_id) {
    //console.log(l_id);
    let postData = {
      l_id: l_id,
    }

    this.isLoadingImage3 = true
    this.detailsdata = false

    window.scrollTo({
      top: 510,
      left: 0, 
      behavior: 'smooth'
    });
    //console.log(postData);

    this.totalcasesummarylwdata = [];

    this.rest
        .getTotalcasesummaryLW(postData)
        .subscribe(
          totalcasesummarylwdata => {
              //console.log(totalcasesummarylwdata);
                this.totalcasesummarylwdata = totalcasesummarylwdata
                this.isLoading = false
                this.status=true;
                this.Totaldetails=true;
                this.details=false;
                this.isLoadingImage3 = false;
                this.detailsdata = true
            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
                this.status=false;
                this.isLoadingImage3 = false;
            }
        );
  }


  getTotalcasesummaryLWSadmin() {
    let postData = {
      l_id: this.search_code,
    }

   //console.log(postData);
   this.totalcasesummarylwdata = [];

    this.rest
        .getTotalcasesummaryLWSadmin(postData)
        .subscribe(
          totalcasesummarylwdata => {
              //console.log(totalcasesummarylwdata);
                this.totalcasesummarylwdata = totalcasesummarylwdata
                this.isLoading = false
                this.status=true;
                this.ssadmin=true;
            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
            }
        );
  }

  getLawyerGroup() {
    let postData = {
      lawyerCode: this.current_laywerCode
    }

    this.lawyergroupdata = [];
    
    this.lawyerinfo_api
        .getLawyerGroup(postData)
        .subscribe(
            lawyergroupdata => {
                this.lawyergroupdata = lawyergroupdata
                this.isLoading = false
            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
            }
        );
  }


  editRemarks(autoIds, lawyer_id, lawyer_auto_id, case_type_id, case_no, case_year, remarks) 
  {

    this.editRow = true;
    this.autoId =autoIds;

    this.lawyer_ids =lawyer_auto_id;
    this.case_type_ids = case_type_id;
    this.case_nos = case_no;
    this.case_years = case_year;
    this.remarks = remarks;

  }

  addRemarks(autoIds, lawyer_id, lawyer_auto_id, case_type_id, case_no, case_year) 
  {

    this.editRow = false;
    this.autoId = autoIds;

    this.lawyer_ids = lawyer_auto_id;
    this.case_type_ids = case_type_id;
    this.case_nos = case_no;
    this.case_years = case_year;

    let postData = {
      lawyer_id: lawyer_auto_id,
      case_type_id: case_type_id,
      case_no: case_no,
      case_year: case_year,
      remarks:this.remarks,
    }

    this.remarksData = [];
    //console.log(this.remarks);

    this.rest
        .saveOrEditAdRemarks(postData)
        .subscribe(
          remarksData => {
                this.remarksData = remarksData
                this.isLoading = false
                this.details=true;
                this.getTotalcaselistaddetails(lawyer_id,case_type_id);
            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
            }
        );
  }

  addRemarksForDetails(autoIds, lawyer_id, lawyer_auto_id, case_type_id, case_no, case_year) 
  {

    this.editRow = false;
    this.autoId = autoIds;

    this.lawyer_ids = lawyer_auto_id;
    this.case_type_ids = case_type_id;
    this.case_nos = case_no;
    this.case_years = case_year;

    let postData = {
      lawyer_id: lawyer_auto_id,
      case_type_id: case_type_id,
      case_no: case_no,
      case_year: case_year,
      remarks:this.remarks,
    }

    //console.log(this.remarks);

    this.remarksData = [];

    this.rest
        .saveOrEditAdRemarks(postData)
        .subscribe(
          remarksData => {
                this.remarksData = remarksData
                this.isLoading = false
                this.details=true;
                this.getTotalcasesummaryLW(lawyer_id);
            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
            }
        );
  }


}
