import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, of } from "rxjs";
import { map, catchError, tap } from "rxjs/operators";
import { formatDate } from "@angular/common";
import { environment } from "src/environments/environment";

//const endpoint = 'http://192.168.0.106:8000/api/';
//const endpoint = 'http://localhost:8888/bdlawservice_admin/public/api/';
//siddiqueenterprise.com

//const endpoint = "https://admin.siddiqueenterprise.com/public/api/";
const endpoint = environment.adminBdlawservice;
 const endpointOther = environment.reactnativeBdlawservice;
const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};

@Injectable({
  providedIn: "root",
})
export class CauselistsearchService {
  constructor(private http: HttpClient) {}

  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

  getCasetype(postData): Observable<any> {
    return this.http
      .post(endpoint + "casetype", postData, httpOptions)
      .pipe(map(this.extractData));
  }

  getCasetypeTitle(id): Observable<any> {
    return this.http
      .post(endpoint + "casetype/" + id, httpOptions)
      .pipe(map(this.extractData));
  }

  getCourtTitle(id): Observable<any> {
    return this.http
      .post(endpoint + "court_detail/" + id, httpOptions)
      .pipe(map(this.extractData));
  }

  getJusticeTitle(id): Observable<any> {
    return this.http
      .post(endpoint + "justice_detail/" + id, httpOptions)
      .pipe(map(this.extractData));
  }

  getCauselisthd(postData): Observable<any> {
    return this.http
      .post(endpoint + "causelisthd", postData, httpOptions)
      .pipe(map(this.extractData));
  }

  getLastPartiesInfoForHistory(postData): Observable<any> {
    return this.http
      .post(endpoint + "getpartiesinfo", postData, httpOptions)
      .pipe(map(this.extractData));
  }

  getCauselisthistory(postData): Observable<any> {
    return this.http
      .post(endpoint + "causelisthistory", postData, httpOptions)
      .pipe(map(this.extractData));
  }

  getCauseListHistoryByCourtService(postData): Observable<any> {
    return this.http
      .post(endpoint + "causelisthistorybycourt", postData, httpOptions)
      .pipe(map(this.extractData));
  }

  getCauseListHistoryByPageService(postData): Observable<any> {
    return this.http
      .post(endpoint + "causelisthistorybypageno", postData, httpOptions)
      .pipe(map(this.extractData));
  }

  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
